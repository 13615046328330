.logo a{
  text-decoration: none;
  color: #282828;
}
.sys-web a:last-child{
  position: relative;
  margin-right: 15px;
}
.sys-web .button.active{
  background: #006DFE;
  color: #fff;
}
.site-header-mobile{
  display: none;
 
 width: 100%;
height: 0px;
 }
 .site-header .header-bottom{

}
.site-header-mobile .inner-nav-mobile{
  position:fixed;
  top: 0;
  width: 100%;
 display: flex;
 background: #fff;
 border-bottom: solid 1px rgb(0,0,0,0.5);
 height: 80px;
 align-items: center;
 width: 100%;
z-index: 800;
 justify-content:space-between;
}
.site-header-mobile .inner-nav-mobile div{
 margin: 30px;
}
.site-header-mobile .inner-nav-mobile div button{
 
 background: #fff;
 border:solid 1px #ccc;
 border-radius: 100px;
 width: 45px;
 height: 45px;
}
.site-header-mobile .inner-nav-mobile .logo-mbl a{
color: #282828;
font-size: 25px;
text-decoration: none;
}
.menu-desktop{
 position: fixed;
}
@media (max-width: 767px) {
 .site-header-mobile{

   display: block;
 }
 .info-media.right img{
  width: 250px;
}

.ft-1 img{
  position: relative;
  margin-top: -20px;
}

.ft-2 img{
  position: relative;
  margin-top:20px;
}

 .menu-desktop{
   display: none;
 }
 .hero-header-content{
   position: relative;
   margin-top: -100px;
   margin-bottom: -85px;
   text-align: center;
 }
 .hero-header-content h1{
   text-align: center;
 }
   .hero-header-content p{
   text-align: center;
 }
 .hero-header-image{
   display: none;
 }
 .section-title{
   text-align: center;
 }
 .menu-mobile button{
  position: relative;
  z-index: 100;
}
 .single-work{
   position: relative;
   margin-bottom: 30px;
 }
 .services-area{
   position: relative;
   margin-top: -45px;
 }

}
 

.slide-menu .inner{
  position: fixed;
   width: 100%;
   top: 80px;
   background: rgb(0,0,0,0.4);
   z-index: 1000;
   height: 100vh;
 }
 .slide-menu .area-menu-slider{
  position:fixed;
    top: 80px;
    right: 0;
    height: 100%;
    background: #fff;
    z-index: 5000;
  }
 .slide-menu .area-menu-slider nav ul{
   display: flex;
   flex-direction: column;
 padding: 20px;
 }
 .slide-menu .area-menu-slider nav ul li{
   padding: 10px;
 }
 
 .slide-menu .area-menu-slider nav ul li a{
   text-decoration: none;
   color: #666;
   font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
  position: relative;

  text-transform: capitalize;
 }

 .slide-menu .area-menu-slider nav ul li a.active{
  border-bottom: solid 1.5px #512b78;
  color: #282828;
}